.slider{
  /* width: 100vw; */
  width: auto;
    /* height: 400px; */
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  padding: 0px 0px;

  .wrapper {
      height: 400px;
      transition: .5s all linear;
      will-change: transform;
  }

  .arrows {
      position: absolute;
      top: 45%;
      background: none;
      height: 60px;
      border: 0;
      cursor: pointer;
      transition: ease .3s all;
      outline: none;

      &.prev {
          left: 0;
  
          &:hover{
              opacity: .7;
              left: -10px;
          }
      }
  
      &.next {
          right: 0;
  
          &:hover{
              right: -10px;
              opacity: .7;
          }
      }
  }

  .toggle-play{
      background: transparent;
      border: none;
      height: auto;
      position: absolute;
      width: auto;
      right: 5%;
      bottom: 9px;
      color: #3d3d3d;
      z-index: 1000000;
  
      &:hover{
          text-decoration: none;
          opacity: .7;
          cursor: pointer;
      }
  }

  .each-slide {
    width: 100vw;
    height: 100vh;
      float: left;
      line-height: 100vh;
      font-size: 40vh;
      text-align: center;
      background-size: cover;
      background-position: center center;
      background-color: transparent;
  }
}

.my-carousel {
    width: 100%;
    height: 300px;
    border-radius: 8px;
  }

  .dots-container{
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 0px;
    gap: 10px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }

  .below-dots {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius : 50%; 
    border : 0.5px solid
  }