.errorClass{
    width: 100%;
    font-size: .875em;
    color: #dc3545;
}

.form-control:focus {
    background-color: #fff;
    border-color: #000;
    box-shadow: 0 0 0 .13rem #000000;
    color: #21313c;
    outline: 0;
}

a:hover {
    color: #21313c !important;
    opacity: 0.9;
}

.nav-link:focus, .nav-link:hover {
    color: #000;
    opacity: 0.9;
}

.btn:hover {
    opacity: 95% !important;
}

.card-product:hover {
    border: 1px solid #000;
}

.nav-lb-tab .nav-item .nav-link.active, .nav-lb-tab .nav-item .nav-link:hover {
    border-bottom:2px solid #000;
}

.form-select:focus {
    border-color: #000;
    box-shadow: 0 0 0 .004rem #000;
}

.btn-social:hover {
    border: 1px solid #000;
    color: #000;
}

.btn:focus-visible {
    border-color: #fff;
    box-shadow: none;
}

.btn:hover {
    /* border-color: #fff; */
    box-shadow: none;
    color: #000;
}

.btn {
    border: 1px solid #889397;
}

.links:hover {
    color: #fff !important;
    opacity: 95% !important;
}

.btn:active{
    border-color: #fff !important;
}