@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

.img-details{
    width: 100%;
    display: block;
    border-radius: 4px;
}
.img-display{
    overflow: hidden;
}
.img-showcase{
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
    border-radius: 6px;
}
.img-showcase .img-details{
    min-width: 100%;
}
.img-select{
    display: flex;
    justify-content: space-between;
}
.img-item{
    margin: 0.3rem;
    flex: 0 0 auto;
    width: 20%;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3){
    margin-right: 0;
}
.img-item:hover{
    opacity: 0.8;
}

.product-imgs{
    gap: 10px;
    display: grid;
}

.detail {
    border: none !important;
    outline: none !important;
}

.detail .button-minus .btn:focus {
    border: none !important;
    outline: none !important;
}

@media screen and (min-width: 992px){
    .product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }
    .product-content{
        padding-top: 0;
    }
}


.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
}

.rating>input {
    display: none
}

.rating>label {
    position: relative;
    width: 1em;
    font-size: 30px;
    font-weight: 300;
    color: #FFD600;
    cursor: pointer
}

.rating>label::before {
    content: "\2605";
    position: absolute;
    opacity: 0
}

.rating>label:hover:before,
.rating>label:hover~label:before {
    opacity: 1 !important
}

.rating>input:checked~label:before {
    opacity: 1
}

.rating:hover>input:checked~label:before {
    opacity: 0.4
}

.ratingComponent {
    height: 37px;
}

/* .button-minus {
    border-color: #d7e2e0 !important;
} */